// 引入axios封装
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import router from '@/router';
import { COMMON_URL } from '../../service/public/IP'

const BASE_URL = COMMON_URL+'/bls/financial/calculatingDesignFee/'

function queryCuttingQty(param: any, result: any) {
    const queryUrl = BASE_URL + "queryCuttingQty";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded'
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data.result;
    }).catch(reason => {
        console.log(reason);
    })
}

function queryDesingers(result: any) {
    const queryUrl = BASE_URL + "queryDesigners";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        }
    }).then(respone => {
        result.value = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}

function importCuttingQty(file: any) {
    const importCuttingUrl = BASE_URL + "importCuttingQty";
    axios({
        method: "post",
        url: importCuttingUrl,
        headers: {
            'Content-type': 'multipart/form-data'
        },
        data: file,
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        if (reason.data == null) {
            ElMessageBox.alert("文件导入失败", '提示信息')
        } else {
            ElMessageBox.alert(reason.data, '提示信息')
        }
    })
}

function calculateDesignFee(data: any) {
    const calculatingDesignFeeUrl = BASE_URL + "calculateDesignFee";
    console.log(calculatingDesignFeeUrl);
    axios({
        method: "post",
        url: calculatingDesignFeeUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded'
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function exportDesignFee(data: any, fileName: any) {
    const exportUrl = BASE_URL + "exportDesignFee";
    console.log(exportUrl);
    axios({
        method: "post",
        url: exportUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob',
        data
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        const content = respone.data
        const blob = new Blob([content], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
        const elink = document.createElement('a')
        elink.download = fileName
        elink.style.display = 'none'
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href)
        document.body.removeChild(elink)
    }).catch(reason => {
        if (reason == 'AxiosError: Request failed with status code 500') {
            ElMessageBox.alert("对不起，您无权访问该功能", '提示信息')
        } else {
            ElMessageBox.alert(reason, '提示信息')
        }
    })
}

function sumData(param: any, result: any) {
    const queryUrl = BASE_URL + "sumData";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded'
        },
        data: param
    }).then(respone => {
        result.total = respone.data;
    }).catch(reason => {
        console.log(reason);
    })
}

function closeData(param: any) {
    const queryUrl = BASE_URL + "closeData";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded'
        },
        data: param
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        console.log(reason);
    })
}

function queryStatus(param: any,result:any) {
    const queryUrl = BASE_URL + "closeData";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded'
        },
        data: param
    }).then(respone => {
        result.value = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}

export {calculateDesignFee,queryDesingers, queryCuttingQty, sumData, importCuttingQty, exportDesignFee,closeData,queryStatus }