// 引入axios封装
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import router from '@/router';
import { COMMON_URL } from '../public/IP'

const BASE_URL = COMMON_URL + '/bls/financial/styleBom/'

function save(data: any) {
    const saveUrl = BASE_URL + "save";
    console.log(saveUrl);
    axios({
        method: "post",
        url: saveUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function queryStyleNo(param: any, result: any, totalQty: any) {
    const queryUrl = BASE_URL + "queryStyleNo";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data.result;
        totalQty.total = respone.data.result.length
    }).catch(reason => {
        console.log(reason);
    })
}

function query(param: any, result: any, addData: any) {
    const queryUrl = BASE_URL + "query";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data.result;

        addData.id = ''
        addData.clothStore=''
        addData.clothNo = ''
        addData.clothName = ''
        addData.colorNo = ''
        addData.color = ''
        addData.unitQty = ''
        addData.edgingsUnitQty = ''
        addData.edgingsType = ''
        addData.remark = ''
    }).catch(reason => {
        console.log(reason);
    })
}

function queryClothProperties(param: any,  addData: any) {
    const queryUrl = BASE_URL + "queryClothProperties";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }

        addData.clothWidth =  respone.data.clothWidth;
        addData.grammage =  respone.data.grammage;
        addData.unit = respone.data.stdQtyUnit;
        addData.price =  respone.data.price;
    }).catch(reason => {
        console.log(reason);
    })
}

function queryClothStore(result: any) {
    const queryUrl = BASE_URL + "queryClothStore";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        }
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data.result;
    }).catch(reason => {
        console.log(reason);
    })
}

function queryCloth(condition:any,result: any) {
    const queryUrl = BASE_URL + "queryCloth";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data:condition
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data.result
    }).catch(reason => {
        console.log(reason);
    })
}

function queryColorNo(condition:any,result: any) {
    const queryUrl = BASE_URL + "queryColorNo";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data:condition
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data.result
    }).catch(reason => {
        console.log(reason);
    })
}

function queryImg(param: any, styleImg: HTMLImageElement) {
    const queryUrl = BASE_URL + "queryImg";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        styleImg.src = respone.data
        //styleImg.src = 'http://43.138.167.176/static/img/test.jpg'
    }).catch(reason => {
        console.log(reason);
    })
}

function deleteCloth(condition:any) {
    const queryUrl = BASE_URL + "deleteCloth";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data:condition
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        console.log(reason);
    })
}

export { save, queryStyleNo, query,queryImg, queryClothProperties,queryClothStore,queryCloth,queryColorNo,deleteCloth }