// 引入axios封装
import axios from 'axios'
import Qs from 'qs'
import { reactive, ref, toRaw, toRefs } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { COMMON_URL } from '../../public/IP'

const BASE_URL = COMMON_URL + '/bls/prod/wsStyleProcessBom/'

function queryStyleList(link:any) {
    const queryUrl = BASE_URL + "queryStyleList";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        link.value = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}

function queryNoPriceStyle() {
    const queryUrl = BASE_URL + "queryNoPriceStyle";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        console.log(reason);
    })
}

function addProcess(param:any,condition: any, result: any) {
    const addUrl = BASE_URL + "addProcess";
    console.log(addUrl);
    axios({
        method: "post",
        url: addUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {       
        const param = Qs.stringify({
            subStyleNo: condition.subStyleNo
        });
        query(param, result)

        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function addDefaultProcess(param:any,condition: any, result: any) {
    const addUrl = BASE_URL + "addDefaultProcess";
    console.log(addUrl);
    axios({
        method: "post",
        url: addUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {       
        const param = Qs.stringify({
            subStyleNo: condition.subStyleNo
        });
        ElMessageBox.alert(respone.data, '提示信息')
        query(param, result)     
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function deleteProcess(param:any,condition:any,result:any) {
    const addUrl = BASE_URL + "deleteProcess";
    console.log(addUrl);
    axios({
        method: "post",
        url: addUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        const param = Qs.stringify({
            styleNo: condition.styleNo
        });
        query(param, result)
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function save(data: any) {
    const addUrl = BASE_URL + "save";
    console.log(addUrl);
    axios({
        method: "post",
        url: addUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function query(param: any, result: any) {
    const queryUrl = BASE_URL + "query";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data.result;
    }).catch(reason => {
        console.log(reason);
    })
}

function queryProcess(styleNo:any,unSelectedProcess: any, selectedProcess: any) {
    const queryUrl = BASE_URL + "queryProcess";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        data: styleNo
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        unSelectedProcess.value = respone.data.unSelectedData
        selectedProcess.value = respone.data.selectedData
    }).catch(reason => {
        console.log(reason);
    })
}

export { save,query,queryNoPriceStyle, queryProcess, addProcess,addDefaultProcess,deleteProcess,queryStyleList}