// 引入axios封装
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import { COMMON_URL } from '../public/IP'

const BASE_URL = COMMON_URL + '/bls/base/employee/'

function save(data: any) {
    const addUrl = BASE_URL + "save";
    console.log(addUrl);
    axios({
        method: "post",
        url: addUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function query(param: any, result: any, addData: any,totalQty:any) {
    const queryUrl = BASE_URL + "query";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data.result;
        totalQty.total = respone.data.result.length
        if (respone.data.result.length > 0) {
            addData.id = respone.data.result[0].id
            addData.employeeNo = respone.data.result[0].employeeNo
            addData.employeeName = respone.data.result[0].employeeName
            addData.factoryNo = respone.data.result[0].factoryNo
            addData.departmentNo = respone.data.result[0].departmentNo
            addData.position = respone.data.result[0].position
            addData.salaryType = respone.data.result[0].salaryType
            addData.baseSalary = respone.data.result[0].baseSalary
            addData.tel = respone.data.result[0].tel
            addData.employeeType = respone.data.result[0].employeeType
            addData.status = respone.data.result[0].status
            addData.remark = respone.data.result[0].remark
        } else {
            addData.id = ''
            addData.employeeNo = ''
            addData.employeeName = ''
            addData.factoryNo = ''
            addData.departmentNo = ''
            addData.position = ''
            addData.salaryType = ''
            addData.baseSalary = ''
            addData.tel = ''
            addData.employeeType = ''
            addData.status = ''
            addData.remark = ''
        }
    }).catch(reason => {
        console.log(reason);
    })
}

function queryAllFactories(result: any) {
    const queryUrl = BASE_URL + "queryAllFactories";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        }
    }).then(respone => {
        result.value = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}

function queryAllDepartments(result: any) {
    const queryUrl = BASE_URL + "queryAllDepartments";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        }
    }).then(respone => {
        result.value = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}

function queryAllPositions(result: any) {
    const queryUrl = BASE_URL + "queryAllPositions";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        }
    }).then(respone => {
        result.value = respone.data
    }).catch(reason => {
        console.log(reason);
    })
}


export { save, query,queryAllPositions,queryAllFactories,queryAllDepartments }