// 引入axios封装
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import { COMMON_URL } from '../public/IP'

const BASE_URL = COMMON_URL + '/system/systemTenant/'

function save(data: any,canModify:any) {
    const addUrl = BASE_URL + "save";
    console.log(addUrl);
    axios({
        method: "post",
        url: addUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
        canModify.value =respone.data.includes('已存在');
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

function query(param: any, result: any, addData: any,totalQty:any) {
    const queryUrl = BASE_URL + "query";
    console.log(queryUrl)
    axios({
        method: "post",
        url: queryUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data: param
    }).then(respone => {
        if (respone.data.errorCode == "111111") {
            ElMessageBox.alert(respone.data.errorMsg, '提示信息')
            return;
        }
        result.value = respone.data.result;
        totalQty.total = respone.data.result.length
        if (respone.data.result.length > 0) {
            addData.id = respone.data.result[0].id
            addData.tenantId = respone.data.result[0].tenantId
            addData.fullCname = respone.data.result[0].fullCname
            addData.fullEname = respone.data.result[0].fullEname
            addData.shortCname = respone.data.result[0].shortCname
            addData.shortEname = respone.data.result[0].shortEname
            addData.addrCn = respone.data.result[0].addrCn
            addData.addrEn = respone.data.result[0].addrEn
            addData.legalPerson = respone.data.result[0].legalPerson
            addData.legalPersonMobile = respone.data.result[0].legalPersonMobile
            addData.contactPerson = respone.data.result[0].contactPerson
            addData.contactMobile = respone.data.result[0].contactMobile
            addData.registerDate = respone.data.result[0].registerDate
            addData.lastPayDate = respone.data.result[0].lastPayDate
            addData.lastPayAmt = respone.data.result[0].lastPayAmt
            addData.deadline = respone.data.result[0].deadline
            addData.status = respone.data.result[0].status
            addData.salesPerson = respone.data.result[0].salesPerson
            addData.remark = respone.data.result[0].remark
        } else {
            addData.id = '';
            addData.tenantId = '';
            addData.fullCname = '';
            addData.fullEname = '';
            addData.shortCname = '';
            addData.shortEname = '';
            addData.addrCn = '';
            addData.addrEn = '';
            addData.legalPerson = '';
            addData.legalPersonMobile = '';
            addData.contactPerson = '';
            addData.contactMobile = '';
            addData.registerDate = '';
            addData.lastPayDate ='';
            addData.lastPayAmt ='';
            addData.deadline ='';
            addData.status = '';
            addData.salesPerson = '';
            addData.remark = '';
        }
    }).catch(reason => {
        console.log(reason);
    })
}

function deleteTenant(data: any) {
    const addUrl = BASE_URL + "delete";
    console.log(addUrl);
    axios({
        method: "post",
        url: addUrl,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Authorization': window.sessionStorage.getItem('token')
        },
        data
    }).then(respone => {
        ElMessageBox.alert(respone.data, '提示信息')
    }).catch(reason => {
        ElMessageBox.alert(reason.data, '提示信息')
    })
}

export { save, query,deleteTenant }